import * as Sentry from '@sentry/react';
import axios from 'axios';
import { serverUrl } from '../global/Environment';
import {
  setTaskDetailLoading,
  setTaskDetailResMsg,
  setCurrentTask,
  setFormUploads,
  setActivityLog,
  setAskLog,
  setComments,
  setEscalationErrors,
  setTaskDetailAvailableTaxReturns,
  setUserEscLog
} from '../actions/taskDetailActions';
import { formatActivityLog, formatComments, formatAskLog, formatUserEscLog } from '../utils/taskUtils';
import { currentTaskSelector, escalationErrorsSelector } from '../selectors/taskDetailSelectors';
import { sentryCaptureException } from '../utils/sentryUtils';

const baseUrl = serverUrl();

export const getTaskDetailData = (taskId, qdeckUserEmailNameMap) => async (dispatch, getState) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.get(`${baseUrl}api/ops-system/task-detail`, {
      params: { taskId }
    });
    const { status, msg, data } = res.data;
    if (status === 'ok') {
      dispatch(setCurrentTask(data.task));
      dispatch(setFormUploads(data.forms));
      dispatch(setComments(formatComments(data.comments, qdeckUserEmailNameMap)));
      dispatch(setActivityLog(formatActivityLog(data.activity, qdeckUserEmailNameMap)));
      dispatch(setAskLog(formatAskLog(data.askLog, qdeckUserEmailNameMap)));
      dispatch(setUserEscLog(formatUserEscLog(data.userEscalationErrors, qdeckUserEmailNameMap)));
      dispatch(setTaskDetailLoading(false));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message || 'Error loading task details. Please try again.'
      })
    );
  } finally {
    const escalationErrors = escalationErrorsSelector(getState());
    if (!escalationErrors) {
      try {
        const escErrorsRes = await axios.get(`${baseUrl}api/ops-system/escalation-errors`);

        if (escErrorsRes?.data?.status === 'ok' && escErrorsRes?.data?.data) {
          dispatch(setEscalationErrors(escErrorsRes.data.data));
        }
      } catch (e) {
        sentryCaptureException(e);

        if (e.message !== 'Network Error' && e.message !== 'Unauthorized Request') {
          Sentry.captureException(e);
        }
      }
    }
  }
};

export const fetchForm = (url) => async (dispatch) => {
  try {
    const res = await axios.get(`${baseUrl}api/form-upload/get-form-download-link`, {
      params: {
        form_url: url
      }
    });
    const { status, msg, data } = res.data;
    if (status === 'ok' && data.signedUrl) {
      window.open(data.signedUrl, '_blank');
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error opening form. Please try again.' })
    );
  }
};

export const runTaskDetailAction = (action, task, user) => async (dispatch) => {
  switch (action) {
    case 'Run Drake upload':
      await dispatch(uploadToDrake({ userId: task.user_id }));
      return;
    case 'Unlock user':
      await dispatch(updateNotStarted({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'Confirm Amounts':
      await dispatch(
        updateConfirmAmounts({
          userId: task.user_id,
          taxYear: task.year,
          reviewer: user.email,
          sendNotification: true
        })
      );
      return;
    case 'Confirm Amounts (no text/email)':
      await dispatch(
        updateConfirmAmounts({
          userId: task.user_id,
          taxYear: task.year,
          reviewer: user.email,
          sendNotification: false
        })
      );
      return;
    case 'Update amounts':
      await dispatch(updateDrakeAmounts({ userId: task.user_id }));
      return;
    case 'Filed with IRS':
      await dispatch(updateFiledWithIrs({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'Accepted by IRS':
      await dispatch(updateAcceptedByIrs({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'Move to Tax Expert Esc':
      await dispatch(updateTaxExpertEsc({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'Move to Ops Review':
      await dispatch(updateOpsReview({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'Move to Final Review':
      await dispatch(updateFinalReview({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'Move to eng esc':
      await dispatch(updateEngEsc({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'DO NOT FILE':
      await dispatch(updateDoNotFile({ userId: task.user_id, taxYear: task.year, reviewer: user.email }));
      return;
    case 'blocked':
      await dispatch(
        updateOpsStatus({
          taskId: task.id,
          fieldUpdate: { blocked: true },
          reviewer: user.email
        })
      );
      return;
    case 'emailOutbox':
      await dispatch(
        updateOpsStatus({
          taskId: task.id,
          fieldUpdate: { emailOutbox: true },
          reviewer: user.email
        })
      );
      return;
    default:
      return;
  }
};

export const updateAssignee = ({ taskId, assignee, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-assignee-2`, { taskId, assignee, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message,
        title: 'Error assigning task'
      })
    );
  }
};

export const updateOpsReview = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-ops-review`, { userId, taxYear, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateConfirmAmounts = ({ userId, taxYear, reviewer, sendNotification }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-confirm-amounts`, {
      userId,
      taxYear,
      reviewer,
      sendNotification,
      origin: 'qdeck'
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateFinalReview = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-final-review`, { userId, taxYear, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateFiledWithIrs = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-filed-with-irs`, { userId, taxYear, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateAcceptedByIrs = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-accepted-by-irs`, { userId, taxYear, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateNotStarted = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-not-started`, { userId, taxYear, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateTaxExpertEsc = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-tax-expert-esc`, { userId, taxYear, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateEngEsc = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-eng-esc`, {
      userId,
      taxYear,
      reviewer,
      origin: 'qdeck'
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateUserEsc = ({ userId, taxYear, origin, userFacingErrors, escalationType }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-user-esc`, {
      userId,
      taxYear,
      origin,
      userFacingErrors,
      escalationType
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const updateDoNotFile = ({ userId, taxYear, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-do-not-file`, {
      userId,
      taxYear,
      reviewer,
      origin: 'qdeck'
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({ error: e.response?.data?.msg || e.message || 'Error updating stage. Please try again.' })
    );
  }
};

export const uploadToDrake = ({ userId }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/drake/upload-to-drake`, { userId, updateReason: 'qdeck drake upload' });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message || 'Error running Drake upload. Please try again.'
      })
    );
  }
};

export const updateDrakeAmounts = ({ userId }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/drake/extract-drake-amount`, { userId, sendNotification: false });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message || 'Error running Drake amounts job. Please try again.'
      })
    );
  }
};

export const updateOpsStatus = ({ taskId, fieldUpdate, reviewer }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-ops-status`, { taskId, fieldUpdate, reviewer });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message || 'Error updating ops status field. Please try again.'
      })
    );
  }
};

export const updateExtensionStatus = ({ taskId, userId, selected, reviewer }) => async (dispatch, getState) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/update-extension-status`, {
      taskId,
      userId,
      selected,
      reviewer
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message || 'Error updating extension status. Please try again.'
      })
    );
  }
};

export const sendInternalComment = ({ userId, taskId, authorEmail, authorName, commentBody, commentTags }) => async (
  dispatch
) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/send-internal-comment`, {
      userId,
      taskId,
      authorEmail,
      authorName,
      commentBody,
      commentTags
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message || 'Error sending internal comment. Please try again.'
      })
    );
  }
};

/**
 * @desc Send Message To User
 *
 * @param {Object} obj SMS payload object
 */
export const sendUserMessage = ({ userId, taskId, keeperId, authorEmail, authorName, text }) => async (dispatch) => {
  try {
    dispatch(setTaskDetailLoading(true));
    const res = await axios.post(`${baseUrl}api/ops-system/send-user-message`, {
      userId,
      taskId,
      keeperId,
      authorEmail,
      authorName,
      text
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskDetailLoading(false));
      dispatch(setTaskDetailResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskDetailLoading(false));
    dispatch(
      setTaskDetailResMsg({
        error: e.response?.data?.msg || e.message || 'Error sending user message. Please try again.'
      })
    );
  }
};

export const getTaskDetailAvailableTaxReturns = () => async (dispatch, getState) => {
  const currentTask = currentTaskSelector(getState());
  const userId = currentTask.user.id;
  const res = await axios.get(`${baseUrl}api/ops-system/task-detail-available-tax-returns`, {
    params: { user_id: userId }
  });
  const { status, msg, data } = res.data;
  if (status === 'ok') {
    dispatch(setTaskDetailAvailableTaxReturns(data));
  } else {
    throw new Error(msg);
  }
};
