import axios from 'axios';
import _ from 'lodash';
import { ALL_SUBSTAGES, TASKS_PER_PAGE, appUrl, serverUrl } from '../global/Environment';
import {
  addPendingReturnDownloadUser,
  removePendingReturnDownloadUser,
  setBulkEditMode,
  setBulkEditTasks,
  setTaskListLoading,
  setTaskListPage,
  setTaskListResMsg,
  setTaskListStageCounts,
  setTaskListSubstageCounts,
  setTaskListTasks
} from '../actions/taskListActions';
import { bulkEditTasksSelector, tasksSelector } from '../selectors/taskListSelectors';
import { userSelector } from '../selectors/authSelectors';
import { setTaskDetailResMsg } from '../actions/taskDetailActions';
import { sentryCaptureException } from '../utils/sentryUtils';

const baseUrl = serverUrl();

export const getTaskListData = () => async (dispatch, getState) => {
  const taskListState = _.get(getState(), 'taskList');
  const currentTasks = _.get(taskListState, 'tasks') || [];
  const currentStageCounts = _.get(taskListState, 'stageCounts') || {};
  const currentSubstageCounts = _.get(taskListState, 'substageCounts') || {};

  try {
    dispatch(setTaskListLoading(true));

    const {
      taxYear,
      stages,
      showAllReturns,
      stageFilter,
      searchFilter,
      assigneeFilter,
      substageFilter,
      order
    } = taskListState;

    const isSubstageView = substageFilter?.length;
    const [taskListRes, countRes] = await Promise.all([
      axios.get(`${baseUrl}api/ops-system/task-list`, {
        params: {
          taxYear,
          searchFilter,
          assigneeFilter,
          stages: _.isEmpty(stageFilter) ? stages : stageFilter,
          showAllReturns: showAllReturns ? '1' : '0',
          substageFilter,
          order
        }
      }),
      ...(isSubstageView
        ? [
            axios.get(`${baseUrl}api/ops-system/task-substage-counts`, {
              params: {
                taxYear,
                stages,
                substages: ALL_SUBSTAGES,
                filterStages: showAllReturns ? '0' : '1'
              }
            })
          ]
        : [
            axios.get(`${baseUrl}api/ops-system/task-stage-counts`, {
              params: {
                taxYear,
                stages,
                filterSubstages: showAllReturns ? '0' : '1'
              }
            })
          ])
    ]);

    const taskListStatus = _.get(taskListRes, ['data', 'status']);
    const countStatus = _.get(countRes, ['data', 'status']);

    if (taskListStatus === 'ok' && countStatus === 'ok') {
      const tasks = _.get(taskListRes, ['data', 'data']);
      dispatch(setTaskListTasks(tasks));

      if (isSubstageView) {
        dispatch(setTaskListSubstageCounts(_.get(countRes, ['data', 'data'])));
      } else {
        dispatch(setTaskListStageCounts(_.get(countRes, ['data', 'data'])));
      }

      if (tasks.length < TASKS_PER_PAGE) {
        dispatch(setTaskListPage(0));
      }

      dispatch(setTaskListLoading(false));
      dispatch(setTaskListResMsg({ success: _.get(taskListRes, ['data', 'msg']) }));
    } else {
      const errorMsg = taskListStatus === 'ok' ? _.get(countRes, ['data', 'msg']) : _.get(taskListRes, ['data', 'msg']);
      throw new Error(errorMsg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskListTasks(currentTasks));
    dispatch(setTaskListStageCounts(currentStageCounts));
    dispatch(setTaskListSubstageCounts(currentSubstageCounts));
    if (currentTasks.length < TASKS_PER_PAGE) {
      dispatch(setTaskListPage(0));
    }
    dispatch(setTaskListLoading(false));
    dispatch(
      setTaskListResMsg({
        error: e.response?.data?.msg || e.message || 'Error loading task list. Please try again.'
      })
    );
  }
};

export const assignNextTask = () => async (dispatch, getState) => {
  const invalidStages = ['Not Started', 'Accepted by IRS', 'DO NOT FILE', 'Purgatory'];

  try {
    dispatch(setTaskListLoading(true));
    const user = userSelector(getState());
    const currentTasks = tasksSelector(getState());
    if (_.isEmpty(currentTasks)) {
      throw new Error('No available tasks to assign');
    }

    const unassignedTask = currentTasks.find((task) => !task.assignee && !invalidStages.includes(task.stage));
    if (!unassignedTask) {
      throw new Error('No available tasks to assign');
    }

    const res = await axios.post(`${baseUrl}api/ops-system/update-assignee-2`, {
      taskId: unassignedTask.id,
      assignee: user.email,
      reviewer: user.email
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskListLoading(false));
      dispatch(setTaskListResMsg({ success: msg }));
      window.open(`${appUrl()}/task-detail/${unassignedTask.id}`, '_blank');
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskListLoading(false));
    dispatch(
      setTaskListResMsg({
        error: e.response?.data?.msg || e.message || 'Error assigning task. Please try again.'
      })
    );
  }
};

export const updateTasks = ({ unassign, assignee, stage, extensionStatus, opsStatus }) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(setTaskListLoading(true));
    const user = userSelector(getState());
    const selectedTaskIds = bulkEditTasksSelector(getState());
    const res = await axios.post(`${baseUrl}api/ops-system/task-list-bulk-update`, {
      reviewer: user.email,
      selectedTaskIds,
      unassign,
      assignee,
      stage,
      extensionStatus,
      opsStatus
    });
    const { status, msg } = res.data;
    if (status === 'ok') {
      dispatch(setTaskListLoading(false));
      dispatch(setTaskListResMsg({ success: msg }));
    } else {
      throw new Error(msg);
    }
  } catch (e) {
    sentryCaptureException(e);
    dispatch(setTaskListLoading(false));
    dispatch(
      setTaskListResMsg({
        error: e.response?.data?.msg || e.message || 'Error occurred. Please try again.'
      })
    );
  } finally {
    dispatch(setBulkEditTasks([]));
    dispatch(setBulkEditMode(null));
    dispatch(getTaskListData());
  }
};

export const downloadUserReturn = ({ userId, year }) => async (dispatch, getState) => {
  dispatch(addPendingReturnDownloadUser(userId));
  try {
    const res = await axios.get(`${baseUrl}api/taxes/generate-drake-pdf-url`, {
      params: {
        userId,
        year
      }
    });
    if (res.data.status !== 'ok') {
      dispatch(removePendingReturnDownloadUser(userId));
      dispatch(setTaskDetailResMsg({ success: false, error: 'Failed to download return' }));
    }
  } catch {
    dispatch(removePendingReturnDownloadUser(userId));
    dispatch(setTaskDetailResMsg({ success: false, error: 'Failed to download return' }));
  }
};
