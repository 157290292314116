import { TASK_DETAIL } from '../../actions/types';

const initialState = {
  loading: false,
  successMsg: '',
  errorMsg: '',
  resTitle: '',
  resFooter: '',
  currentTask: {},
  formUploads: [],
  activityLog: [],
  userEscLog: {},
  askLog: [],
  comments: [],
  tpInfoVisible: true,
  substagesVisible: true,
  opsInfoVisible: true,
  escalationErrors: null,
  modalType: null,
  availableTaxReturns: []
};

export default function taskDetailReducer(state = initialState, action) {
  switch (action.type) {
    case TASK_DETAIL.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case TASK_DETAIL.SET_RES_MSG:
      return {
        ...state,
        successMsg: action.payload.success,
        errorMsg: action.payload.error,
        resTitle: action.payload.title,
        resFooter: action.payload.footer
      };
    case TASK_DETAIL.SET_TASK:
      return {
        ...state,
        currentTask: action.payload
      };
    case TASK_DETAIL.SET_UPLOADS:
      return {
        ...state,
        formUploads: action.payload
      };
    case TASK_DETAIL.SET_ACTIVITY:
      return {
        ...state,
        activityLog: action.payload
      };
    case TASK_DETAIL.SET_ASK_LOG:
      return {
        ...state,
        askLog: action.payload
      };
    case TASK_DETAIL.SET_USER_ESC_LOG:
      return {
        ...state,
        userEscLog: action.payload
      };
    case TASK_DETAIL.SET_COMMENTS:
      return {
        ...state,
        comments: action.payload
      };
    case TASK_DETAIL.SET_TP_INFO_VISIBLE:
      return {
        ...state,
        tpInfoVisible: action.payload
      };
    case TASK_DETAIL.SET_SUBSTAGES_VISIBLE:
      return {
        ...state,
        substagesVisible: action.payload
      };
    case TASK_DETAIL.SET_OPS_INFO_VISIBLE:
      return {
        ...state,
        opsInfoVisible: action.payload
      };
    case TASK_DETAIL.SET_ESC_ERRORS:
      return {
        ...state,
        escalationErrors: action.payload
      };
    case TASK_DETAIL.SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload
      };
    case TASK_DETAIL.SET_AVAILABLE_TAX_RETURNS:
      return {
        ...state,
        availableTaxReturns: action.payload
      };
    default:
      return state;
  }
}
