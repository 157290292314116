import _ from 'lodash';
import { createSelector } from 'reselect';
import { pendingReturnDownloadUsersSelector } from './taskListSelectors';

export const taskDetailLoadingSelector = (state) => _.get(state, ['taskDetail', 'loading']);
export const taskDetailSuccessMsgSelector = (state) => _.get(state, ['taskDetail', 'successMsg']);
export const taskDetailErrorMsgSelector = (state) => _.get(state, ['taskDetail', 'errorMsg']);
export const taskDetailResTitleSelector = (state) => _.get(state, ['taskDetail', 'resTitle']);
export const taskDetailResFooterSelector = (state) => _.get(state, ['taskDetail', 'resFooter']);
export const currentTaskSelector = (state) => _.get(state, ['taskDetail', 'currentTask']);
export const formUploadsSelector = (state) => _.get(state, ['taskDetail', 'formUploads']);
export const activityLogSelector = (state) => _.get(state, ['taskDetail', 'activityLog']);
export const askLogSelector = (state) => _.get(state, ['taskDetail', 'askLog']);
export const userEscLogSelector = (state) => _.get(state, ['taskDetail', 'userEscLog']);
export const commentsSelector = (state) => _.get(state, ['taskDetail', 'comments']);
export const tpInfoVisibleSelector = (state) => _.get(state, ['taskDetail', 'tpInfoVisible']);
export const substagesVisibleSelector = (state) => _.get(state, ['taskDetail', 'substagesVisible']);
export const opsInfoVisibleSelector = (state) => _.get(state, ['taskDetail', 'opsInfoVisible']);
export const escalationErrorsSelector = (state) => _.get(state, ['taskDetail', 'escalationErrors']);
export const modalTypeSelector = (state) => _.get(state, ['taskDetail', 'modalType']);
export const isPendingReturnDownloadSelector = createSelector(
  [currentTaskSelector, pendingReturnDownloadUsersSelector],
  (task, pendingReturnDownloadUsers) => _.includes(pendingReturnDownloadUsers, _.get(task, 'user_id'))
);
export const availableTaxReturnsSelector = (state) => _.get(state, ['taskDetail', 'availableTaxReturns']);
